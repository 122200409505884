.backToTop {
  position: sticky;
  top: calc(100vh - 64px);
  text-align: right;
  margin-bottom: 24px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility 0s 0.3s,
    opacity 0.3s ease-in-out;

  &Visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease-in-out;
  }

  button {
    box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.25);
    pointer-events: auto;
  }
}

.triggerPoint {
  position: absolute;
  top: 1000px;
  left: 0;
  width: 1px;
  height: 1px;
}

section[class*='GridItemArticle_body'] {
  position: relative;
}

div[class~='back-to-top-wrapper'] {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
